import { emitter } from './common'
import { gtmVirtualPageView } from './utilities/Gtm'
import qs from 'qs'
import { type LocationQuery, type LocationQueryRaw, type RouteLocationNormalized } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router/auto'
import { routes } from 'vue-router/auto-routes'

console.log('routes', routes)
const router = createRouter({
  history: createWebHashHistory(),
  parseQuery: (query: string) => qs.parse(query) as LocationQuery,
  stringifyQuery: (query: LocationQueryRaw) => qs.stringify(query) || '',
  extendRoutes: (routes) => [
    // redirect old routes from old links in emails etc.
    // @TODO: remove when grown out
    {
      path: '/project/:id/inquiries-create/:inquiryId',
      redirect: (to) => ({ name: '/inquiries/[id]', params: { id: to.params.inquiryId } }),
    },

    {
      path: '/projects/:id/inquiries/:inquiryId',
      redirect: (to) => ({ name: '/inquiries/[id]', params: { id: to.params.inquiryId } }),
    },
    { path: '/products-detail/:id', redirect: { name: '/products/[id]' } },
    ...routes,
  ],
})

router.beforeEach((to, from, next) => {
  if (from && to.name !== from.name) {
    emitter.emit('routeLeave', from.name)
  }

  next()
})

router.onError((e) => {
  emitter.emit('routeError', e)
})

router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  if (!from || to.name !== from.name) {
    setTimeout(() => emitter.emit('routeChange', to.name), 600)
  }
  if (!to.name) {
    return
  }

  const title = to.meta?.title ? to.meta.title : to.name
  gtmVirtualPageView(title as string, to.fullPath)
})

export default router
